.intro {
    display: flex;
    height: 100vh;
}
.intro-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.intro-right {
    flex: 1;
    position: relative;
}

.intro-left-wrapper {
    width: 100%;
    padding: 50px;
    height: 35%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.intro-intro {
    font-size: 40px;
    font-weight: 300;
}

.intro-name {
    font-size: 70px;
}

.intro-title {
    height: 60px;
    overflow: hidden;
}

.intro-title-wrapper {
    height: 100%;
    animation: move 10s ease-in-out infinite alternate;
}

.intro-desc {
    font-size: 20px;
    text-overflow: clip;
}

@keyframes move {
    33%{
        transform: translateY(-50px)
    }
    66%{
        transform: translateY(-100px)
    }
    100%{
        transform: translateY(-150px)
    }
}

.intro-title-item {
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    color: #2FBCFD;
    position: relative;
    display: flex;
    align-items: center;
}

.intro-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}

.intro-bg {
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
    background-color: #2FBCFD;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}    

@media screen and (max-width: 1074px) {
    .intro {
        flex-direction: column;
    }
    .intro-img {
        object-fit: contain;
        width: 100%;
    }

    .intro-left-wrapper {
        padding: 20px;
        align-items: center;
        text-align: center;
        justify-content: center;
        height: 100%;
    }
    .intro-title-item {
        justify-content: center;
    }
    .intro-desc {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .intro {
        flex-direction: column;
    }
    .intro-img {
        object-fit: contain;
        width: 100%;
    }

    .intro-left-wrapper {
        padding: 20px;
        align-items: center;
        text-align: center;
        justify-content: center;
        height: 100%;
    }
    .intro-title-item {
        justify-content: center;
    }
    .intro-name {
        font-size: 50px;
    }
    .intro-desc {
        display: none;
    }
}