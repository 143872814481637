.projCont {
    align-items: center;
    justify-content: center;
    /* display: flex; */
    /* margin: 0px;
    padding: 0px; */
}
.project {
    width: 475px;
    height: 250px;
    margin: 50px 0px;
    border-radius: 20px;
    overflow: hidden;
    object-fit: contain;
    background-size: contain;
    filter: drop-shadow(4px 4px 15px #4444);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform .2s ease-out 100ms
}

.project-img {
    /* height: 100%; */
    
    width: 100%;
    border-radius: 20px; 
    transition: transform .2s ease-out 100ms
}
.infoCont {
    text-align: start;
    text-wrap: wrap;
    width: 450px;
}
.title {
    font-size: 1.7em;
    padding-bottom: 20px;
}
.desc {

}

/* .project-img:hover {
    transform: scale(1.2)
} */
.project:hover {
    transform: scale(1.1)
}

@media screen and (max-width: 480px) and (min-width: 280px) {
    .projCont {
        align-items: center;
        display: inline;
        justify-content: center;
    }
    .infoCont {
        min-width: 260px;
        width: 100%;
    }
    .project {
        width: 100%;
        height: auto;
    }

}

@media screen and (max-width: 280px) {
    .projCont {
        align-items: center;
        display: inline;
        justify-content: center;
    }
    .infoCont {
        min-width: 250px;
        width: 90%;
        margin: 20px;
        border: 1px solid blue;
    }
    .project {
        width: 80vw;
        /* height: auto; */
        /* border: 1px solid green; */
    }
    .project-img {
        /* width: 80%; */
    }

}

/* @media screen and (max-width: 1074px) and (min-width: 480px) {
    .projCont {
        align-items: center;
        display: flex;
        justify-content: center;
    }
    .infoCont {
        width: 100%;
        padding-left: 40px;
    }
    .project {
        min-width: 300px;
        width: 50%;
        height: auto;
    }

} */