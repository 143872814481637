.navWrap {
    position: sticky;
    display: flex;
    justify-content: space-around;
    width: 60%;
    border: 1px solid red;
}
.navBar {
    position: sticky;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 10px 10px;
}
html {
    scroll-behavior: smooth;
}