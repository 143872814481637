.about {
  height: auto;
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-color: #333;
  color: white;
}

.about-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
}
.about-right {
  flex: 1;
  margin-right: 10px;
}
.about-card.bg {
  position: absolute;
  top: 50px;
  left: 50px;
  background-color: #2fbcfd;
}

.about-card {
  width: 60%;
  height: 70vh;
  border-radius: 30px;
  /* position: absolute; */
  overflow: hidden;
  filter: drop-shadow(5px 5px 20px #4444);
}

.about-title {
  font-size: 45px;
  font-weight: bold;
}

.about-sub {
  font-size: 23px;
  margin-top: 40px;
  margin-bottom: 30px;
}

.about-desc {
  font-size: 18px;
  font-weight: 300;
}

.about-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.about-hobbies-wrap {
  /* background-color: green; */
  display: flex;
  align-items: center;
  justify-content:space-around;
  width: 95%;
}
.about-hobbies {
  display: flex;
  margin-top: 60px;
  margin-bottom: 200px;
  position: relative;
  width: 25%;
  height: auto;
}

.about-hobbies-card.bg {
  position: absolute;
  width: 100%;
  top: -20px;
  left: -20px;
  background-color: #2fbcfd;
}
.about-hobbies-card {
  width: 100%;
  height: 15vh;
  border-radius: 20px;
  position: absolute;
  overflow: hidden;
  filter: drop-shadow(2px 2px 10px #4444);
}
.about-hobbies-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1074px) {
  .about {
    min-height: auto;
    flex-direction: column;
    text-align: center;
    padding-bottom: 50px;
  }
  .about-left {
    width: 100%;
    margin-top: 50px;
  }
  .about-card {
    height: 20vh;
  }
  .about-card.bg {
    display: none;
  }
  .about-right {
    padding: 20px;
  }
  .about-hobbies {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .about {
    flex-direction: column;
    text-align: center;
    min-height: auto;
    padding-bottom: 50px;
  }
  .about-img {
    display: none;
  }
  .about-left {
    display: none;
  }
  .about-card {
    display: none;
  }
  .about-card.bg {
    display: none;
  }
  .about-right {
    margin-top: 60px;
    padding: 20px;
  }
  .about-hobbies {
    display: none;
  }
}