.projects {
    min-height: 90vh;
    height: auto;
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.projects-texts {
    width: 85%;
}

.projects-title {
    font-size: 50px;
    font-weight: 600;
}

.projects-desc {
    font-size: 23px;
    margin: 20px 0px;
}

.projectList-list {
    /* background-color: green; */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

@media screen and (max-width: 280px) {
   .projects {
    border: 1px solid yellow;
   }
   .projectList-list {
    display:flex;
    border: 1px solid red;
    justify-content: center;
    width: 100vw;
    align-items: center;
}

}
@media screen and (max-width: 1074px) {
    
    .projects {
        min-height: auto;
        /* width: 100%; */
        /* padding: 50px; */
    }
    .projectList-list {
        display:flex;
        justify-content: center;
        /* align-items: center; */
    }
    .projects-title {
        font-size: 2.5em;
    }
    .projects-texts {
        width: 80vw;
    }
    .projects-desc {
        display: none;
    }

}


