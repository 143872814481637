.productList {
    min-height: 90vh;
    height: auto;
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #333;
    color: white;
}

.productList-texts {
    width: 85%;
}

.productList-title {
    font-size: 50px;
    font-weight: 600;
}

.productList-desc {
    font-size: 23px;
    margin: 20px 0px;
}

.productList-list {
    /* background-color: green; */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

@media screen and (max-width: 1074px) {
    
    .productList {
        min-height: auto;
        padding: 50px;
    }
    .productList-list {
        display:flex;
        justify-content: space-evenly;
        align-items: center;
    }
    .productList-texts {
        width: 95vw;
    }
    .productList-title {
        font-size: 2.5em;
    }
    .productList-desc {
        display: none;
    }

}