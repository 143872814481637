.product {
    width: 400px;
    height: 500px;
    margin: 50px 0px;
    /* border: 3px solid rgb(219, 219, 219); */
    border-radius: 20px;
    overflow: hidden;
    object-fit: cover;
    filter: drop-shadow(4px 4px 15px #00000044);
}

.product-img {
    height: 100%;
}

.product:hover .product-img {
    animation-name: translate;
    animation-duration: 5s;
}

@keyframes translate {
    0% {
        transform: translateX(0%);
    }
    20% {
        transform: translateX(-20%);
    }
    40% {
        transform: translateX(-40%);
    }
    60% {
        transform: translateX(-60%);
    }
    80% {
        transform: translateX(-80%);
    }
}

@media screen and (max-width: 1074px) {
    .product {
        height: auto;
        aspect-ratio: 4 / 5;
    }
}