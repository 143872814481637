.contact {
    height: 100vh;
    display: relative;
}

.contact-bg {
    width: 20px;
    height: 100%;
    background-color: #2fbcfd;
    position: absolute;
}

.contact-wrap {
    display: flex;
    margin: 50px;
}

.contact-left {
    flex: 1;
    margin-left: 20px;
}

.contact-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contact-title {
    font-size: 70px;
    font-weight: bold;
    width: 80%;
    margin-left: 20px;
}
.contact-info-item {
    display: flex;
    align-items: center;
    margin: 50px 0px;
    margin-left: 20px;
    font-size: 20px;
    font-weight: 300px;
    width: 70%;
}

.contact-icon {
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

.contact-desc {
    font-size: 20px;
    font-weight: 300;
}

form {
    margin-top: 20px;
}

input {
    width: 50%;
    height: 50px;
    border: none;
    border-bottom: 1px solid black;
    margin: 10px 0px;
    font-size: 18px;
    padding-left: 10px
}

textarea {
    width: 100%;
    margin: 10px 0px;
    font-size: 18px;
    padding-left: 10px
}

.submitBtn {
    border: none;
    padding: 15px;
    background-color: #2fbcfd;
    color: white;
    font-weight: 500;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 0 2px #333;
}

.submitMsg {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 200;
}

@media screen and (max-width: 1074px) {
    .contact {
        height: 40vh;
        display: flex;
        justify-content: center;
    }
    .contact-left {
        margin-left: 0px;
    }
    .contact-bg {
        display: none;
    }
    .contact-wrap{
        flex-direction: column;
        /* padding: 0px 15px; */
    }
    .contact-title {
        font-size: 45px;
        text-align: center;
        width: 100%;
        margin: 0px 0px;
    }
    .contact-info-item {
        margin: 20px 0px;
        width: 100%;
        font-size: 15px;
    }
    .contact-desc {
        display: none;
    }
    form {
        margin-top: 0px;
        display: none;
        flex-wrap: wrap;
    }
    .submitBtn {
        margin-top: 10px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 480px) {
    .contact {
        height: 40vh;
    }
    .contact-left {
        padding: 0px;
        margin-left: 0px;
    }
    .contact-bg {
        display: none;
    }
    .contact-wrap{
        flex-direction: column;
        /* padding: 0px 15px; */
    }
    .contact-title {
        font-size: 45px;
        text-align: center;
        width: 100%;
        margin: 0px 0px;
    }
    .contact-info-item {
        margin: 20px 0px;
        width: 100%;
        font-size: 15px;
    }
    .contact-desc {
        display: none;
    }
    form {
        margin-top: 0px;
        display: none;
        flex-wrap: wrap;
    }
    .submitBtn {
        margin-top: 10px;
        margin-bottom: 20px;
    }
}
